import React, {memo} from 'react';
import {graphql} from 'gatsby';

import {Trans} from 'gatsby-plugin-react-i18next';
import PageWrapper from '../../containers/PageWrapper';
import ContactForm from '../../modules/ContactForm';
import ImageSection from '../../components/ImageSection';
import Section from '../../components/Section';
import UnderlineHeading from '../../components/UnderlineHeading';
import SectionDescription from '../../components/SectionDescription';
import HorizontalCards from '../../components/HorizontalCards';
import SmallCards from '../../components/SmallCards';

const CustomerPlatforms = ({data}) => (
  <PageWrapper
    title="Customer Platforms"
    description="Customers nowadays expect access to information and possibility to manage their subscriptions or purchases on their own. Self-care portals and customer engagement platforms allow them to do so. With slick and modern UI and right features your users may be the best advocates of your products and success reducing your cost on customer support side."
    urlPath="solutions/customer-platforms"
    keywords={['coderylabs', 'customer platforms']}
  >
    <ImageSection fluid={data.mainPhoto.childImageSharp.fluid}/>

    <Section>
        <UnderlineHeading><Trans>Customer platforms</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Customer platforms desc</Trans>
      </SectionDescription>
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Features</Trans></UnderlineHeading>
      <SectionDescription style={{marginBottom: '2rem'}}>
        <Trans>Customer platforms features desc</Trans>
      </SectionDescription>

      <SmallCards
        items={[
          {
            fluid: data.chatbot.childImageSharp.fluid,
              heading: <Trans>Chatbot</Trans>,
              description: <Trans>Chatbot desc</Trans>,
          },
          {
            fluid: data.richAnalytics.childImageSharp.fluid,
              heading: <Trans>Rich Analytics</Trans>,
              description: <Trans>Rich Analytics desc</Trans>,
          },
          {
            fluid: data.userInformation.childImageSharp.fluid,
              heading: <Trans>User Information</Trans>,
            description: <Trans>User Information desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.formsBuilder.childImageSharp.fluid,
              heading: <Trans>Forms Builder</Trans>,
            description: <Trans>Forms Builder desc</Trans>,
          },
          {
            fluid: data.omnichannel.childImageSharp.fluid,
              heading: <Trans>Omnichannel</Trans>,
            description: <Trans>Omnichannel desc</Trans>,
          },
          {
            fluid: data.personalisedExperience.childImageSharp.fluid,
              heading: <Trans>Personalised Experience</Trans>,
              description: <Trans>Personalised Experience desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.newsletter.childImageSharp.fluid,
              heading: <Trans>Newsletter</Trans>,
              description: <Trans>Newsletter desc</Trans>,
          },
          {
            fluid: data.gdprCompliant.childImageSharp.fluid,
              heading: <Trans>Gdpr Compliant</Trans>,
              description: <Trans>Gdpr Compliant desc</Trans>,
          },
          {
            fluid: data.selfServiceFeatures.childImageSharp.fluid,
              heading: <Trans>Self-Service Features</Trans>,
              description: <Trans>Self-Service Features desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section>
        <UnderlineHeading><Trans>Benefits</Trans></UnderlineHeading>
        <SectionDescription><Trans>Benefits desc cust platforms</Trans></SectionDescription>

      <HorizontalCards
        theme="dark"
        items={[
          {
            fluid: data.rapidInformationAccess.childImageSharp.fluid,
              heading: <Trans>Rapid information access</Trans>,
              description: <Trans>Rapid information access desc</Trans>,
          },
          {
            fluid: data.increasedAvailability.childImageSharp.fluid,
              heading: <Trans>Increased Availability</Trans>,
              description: <Trans>Increased Availability desc</Trans>,
          },
          {
            fluid: data.loweredCosts.childImageSharp.fluid,
              heading: <Trans>Lowered Costs</Trans>,
              description: <Trans>Lowered Costs desc</Trans>,
          },
          {
            fluid: data.flexibility.childImageSharp.fluid,
              heading: <Trans>Flexibility</Trans>,
              description: <Trans>Flexibility cust platforms desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Contact Us Hint</Trans>
      </SectionDescription>
      <ContactForm/>
    </Section>
  </PageWrapper>
);

export default memo(CustomerPlatforms);

export const query = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "solutions/customerPlatforms/main_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rapidInformationAccess: file(relativePath: { eq: "solutions/customerPlatforms/cards/rapid_information_access.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    increasedAvailability: file(relativePath: { eq: "solutions/customerPlatforms/cards/increased_availability.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    loweredCosts: file(relativePath: { eq: "solutions/customerPlatforms/cards/lowered_costs.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flexibility: file(relativePath: { eq: "solutions/customerPlatforms/cards/flexibility.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    chatbot: file(relativePath: { eq: "solutions/customerPlatforms/cards/chatbot.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    richAnalytics: file(relativePath: { eq: "solutions/customerPlatforms/cards/rich_analytics.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    userInformation: file(relativePath: { eq: "solutions/customerPlatforms/cards/user_information.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    formsBuilder: file(relativePath: { eq: "solutions/customerPlatforms/cards/forms_builder.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    omnichannel: file(relativePath: { eq: "solutions/customerPlatforms/cards/omnichannel.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    personalisedExperience: file(relativePath: { eq: "solutions/customerPlatforms/cards/personalised_experience.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    newsletter: file(relativePath: { eq: "solutions/customerPlatforms/cards/newsletter.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gdprCompliant: file(relativePath: { eq: "solutions/customerPlatforms/cards/gdpr_compliant.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    selfServiceFeatures: file(relativePath: { eq: "solutions/customerPlatforms/cards/self-service_features.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
